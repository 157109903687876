import "./header.scss";
import {
  createStyles,
  Header,
  Group,
  Burger,
  Button,
  Avatar,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import AuthService from "../../services/api";
import fireToast from "../../helper/toaster";

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

export default function HeaderSearch(props) {
  const [isClicked, setIsClicked] = useState(false);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const id = 138260;
    AuthService.getUserDetail(id).then(
      (response) => {
        console.log(response.data.data);
        if (response && response?.data && response?.data?.data) {
          setData(response.data.data);
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        fireToast("error", message, undefined, true);
      }
    );
  }, []);

  return (
    <Header height={56} className={classes.header}>
      <div className={classes.inner}>
        <Group style={{ marginLeft: "0.6rem" }}>
          <Burger opened={props.opened} onClick={props.toggle} size="sm" />
        </Group>

        <Group>
          <Group ml={50} spacing={5} className={classes.links}>
            <Button
              variant="light"
              onClick={() => {
                if (localStorage.getItem("user_role") === "admin") {
                  navigate("/admin/dashboard");
                } else if (
                  localStorage.getItem("user_role") === "content_feeder" ||
                  localStorage.getItem("user_role") === "content_validator"
                ) {
                  navigate("/cm/dashboard");
                }
              }}
            >
              Home
            </Button>
            <Button
              variant="light"
              color={"red"}
              onClick={() => {
                localStorage.clear();
                dispatch(logout()); // Dispatch the LOGOUT action
                navigate("/login");
              }}
            >
              Logout
            </Button>
          </Group>
          <div className="header_profile_container">
            <Avatar
              color="cyan"
              radius="xl"
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            />
            {isClicked && (
              <div className="header_onclick_container">
                <div className="header_user_container">
                  <p className="header_username">{data.username}</p>
                  <p className="header_user_id">ID : {data.userId}</p>
                </div>
                <button
                  onClick={() => {
                    localStorage.clear();
                    dispatch(logout()); // Dispatch the LOGOUT action
                    navigate("/login");
                  }}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </Group>
      </div>
    </Header>
  );
}
