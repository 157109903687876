import headerlogo from "../assets/img/Images/logo.svg";
import fullscreen from "../assets/img/Images/fullsceen.svg";
import dropdown from "../assets/img/Images/dropdown.svg";
import finish from "../assets/img/Images/finish.svg";
import report from "../assets/img/Images/report.svg";
import filter from "../assets/img/Images/filter.svg";
import pause from "../assets/img/Images/pause.svg";
import resume from "../assets/img/Images/resume.svg";
import exam_report from "../assets/img/Images/exam_report.svg";
import login_error from "../assets/img/Images/login_error.svg";
import story_view from "../assets/img/Images/storyView.svg";
import story_like from "../assets/img/Images/storyLike.svg";
import story_share from "../assets/img/Images/storyShare.svg";
import drag_upload from "../assets/img/Images/dragUpload.svg";
import upload_more from "../assets/img/Images/uploadMore.svg";
import story_cross from "../assets/img/Images/storyCross.svg";
import add_svg from "../assets/img/Images/addSvg.svg";
import preview_like from "../assets/img/Images/previewLike.svg";
import preview_share from "../assets/img/Images/previewShare.svg";
import preview_volume from "../assets/img/Images/previewVolume.svg";
import edit_svg from "../assets/img/Images/edit_svg.svg";
import question_check from "../assets/img/Images/questionCheck.svg";
import user_role from "../assets/img/Images/user_role.svg";
import reset_password from "../assets/img/Images/reset_password.svg";
import view_solution_eye from "../assets/img/Images/view_solution_eye.svg";
import dashboard_icon from "../assets/img/Images/dashboardSidebarSvg.svg";
import dashboard_icon_blue from "../assets/img/Images/DashBoardBlue.svg";
import key_icon from "../assets/img/Images/adminKeySidebarSvg.svg";
import key_white from "../assets/img/Images/KeyWhite.svg";
import tasks_icon from "../assets/img/Images/tasksSidebar.svg";
import claimed_req_icon from "../assets/img/Images/moneyClaimedRequestSidebar.svg";
import rupees_icon from "../assets/img/Images/rupees_icon.svg";
import four_dots_grid from "../assets/img/Images/four_dots.svg";
import verified_icon from "../assets/img/Images/verified_icon.svg";
import total_tasks_icon from "../assets/img/Images/total_tasks_tics.svg";
import tasks_pending from "../assets/img/Images/tasksPending.svg";
import history_icon from "../assets/img/Images/earning_history.svg";
import help_faq_icon from "../assets/img/Images/help_faq.svg";
import back_btn_black from "../assets/img/Images/back_btn_black.svg";
import rejected_tasks from "../assets/img/Images/Rejected_tasks.svg";
import rejected_tasks_blue from "../assets/img/Images/Rejected_tasks_blue.svg";
import education_white from "../assets/img/Images/Education_white.svg";
import education_blue from "../assets/img/Images/education_blue.svg";
import billing_blue from "../assets/img/Images/billing_blue.svg";
import billing_white from "../assets/img/Images/billings_white.svg";
import tasks_white from "../assets/img/Images/tasks_white.svg";
import rupees_icon_white from "../assets/img/Images/rupees_icon_white.svg";
import help_faq_white from "../assets/img/Images/help_faq_white.svg";
import empty_task_list from "../assets/img/Images/empty_task_list.svg";
import configuration_white from "../assets/img/Images/configuration_white.svg";
import configuration_blue from "../assets/img/Images/configuration_blue.svg";
import dropdown_key from "../assets/img/Images/dropdown_key.svg";
import profile_pic from "../assets/img/Images/profile_pic.svg";
import happy_emoji from "../assets/img/Images/happy_emoji.svg";
import report_trophy from "../assets/img/Images/report_trophy.svg";
import report_timer from "../assets/img/Images/report_timer.svg";
import report_accuracy from "../assets/img/Images/report_accuracy.svg";
import section_overall_attempt from "../assets/img/Images/section_overall_attempt.svg";
import section_score from "../assets/img/Images/section_score.svg";
import section_accuracy from "../assets/img/Images/section_accuracy.svg";
import section_timer from "../assets/img/Images/section_timer.svg";
import sad_emoji from "../assets/img/Images/sad_emoji.svg";
import neutral_emoji from "../assets/img/Images/neutral_emoji.svg";
import submit_test from "../assets/img/Images/submit_test.svg";
import view_result from "../assets/img/Images/view_result.svg";
import solution_timer from "../assets/img/Images/solution_timer.svg";
import report_leadership from "../assets/img/Images/report_leadership.svg";
import report_rank_first from "../assets/img/Images/report_rank_first.svg";
import report_rank_second from "../assets/img/Images/report_rank_second.svg";
import report_rank_third from "../assets/img/Images/report_rank_third.svg";
import re_attempt_lock from "../assets/img/Images/re_attempt_lock.svg";
import report_loading_gif from "../assets/img/Images/report_loading_gif.gif";

const Image_Prefix = {
  headerlogo,
  fullscreen,
  dropdown,
  finish,
  report,
  filter,
  pause,
  exam_report,
  login_error,
  resume,
  story_view,
  story_like,
  story_share,
  drag_upload,
  upload_more,
  story_cross,
  add_svg,
  preview_like,
  preview_share,
  preview_volume,
  edit_svg,
  question_check,
  user_role,
  reset_password,
  view_solution_eye,
  dashboard_icon,
  dashboard_icon_blue,
  key_icon,
  tasks_icon,
  help_faq_icon,
  claimed_req_icon,
  rupees_icon,
  four_dots_grid,
  verified_icon,
  total_tasks_icon,
  tasks_pending,
  history_icon,
  key_white,
  help_faq_icon,
  back_btn_black,
  rejected_tasks,
  education_white,
  education_blue,
  billing_blue,
  tasks_white,
  rejected_tasks_blue,
  rupees_icon_white,
  billing_white,
  help_faq_white,
  empty_task_list,
  configuration_white,
  configuration_blue,
  dropdown_key,
  profile_pic,
  happy_emoji,
  report_trophy,
  report_timer,
  report_accuracy,
  section_overall_attempt,
  section_score,
  section_accuracy,
  section_timer,
  sad_emoji,
  neutral_emoji,
  submit_test,
  view_result,
  solution_timer,
  report_leadership,
  report_rank_first,
  report_rank_second,
  report_rank_third,
  re_attempt_lock,
  report_loading_gif,
};

export default Image_Prefix;
